<template>
  <div class="sla">
    <section class="banner">
      <div class="container">
        <p class="banner__text1">Удобно</p>
        <p class="banner__text2">когда на облаке</p>
      </div>
    </section>

    <div class="info">
      <div class="container">
        <p>
          В разделе sla Текс надо заменить на
          <br>
          <br>
          «Соглашение об уровне обслуживания (оказания услуги)», то есть это договор об уровне предоставляемого сервиса между компанией-провайдером и организацией-клиентом. Основное отличие SLA от обычного договора состоит в подробно прописанном уровне доступности сервиса и времени реакции на инциденты и раскрывает следующее:
          <br>
          <br>
          прозрачность – снижается количество размытых формулировок, которые можно было бы двояко истолковывать, что способствует тому, что пользователи услуги и компания-провайдер говорят на одном языке и оперируют одними и теми же терминами;
          четкое определение обязанностей для всех сторон, как использующих услугу, так и предоставляющих сервис;
          управление ожиданиями, например, отсутствие недовольства за отказ работать ночью в выходные или отсутствие завышенных требований к качеству предоставления сервиса.
          В соглашении SLA в обязательном порядке должны быть указаны сроки для решения инцидентов и определяются штрафы, которые обязуется выплатить компания-провайдер в том случае, если значения метрик, определяющих качество услуги, окажутся ниже заявленного уровня. Все это поможет организации-заказчику минимизировать убытки в случае незапланированного простоя.
          <br>
          <br>
          Важно помнить, что использование SLA выгодно обеим сторонам:
          <br>
          <br>
          компания-провайдер будет ограждена от внезапно возникающих новых требований к качеству предоставляемого сервиса;
          у организации-клиента будет уверенность, что инциденты будут устранены в указанный срок, что позволит лучше планировать собственную деятельность.
        </p>
      </div>
    </div>

    <div class="panel">
      <div class="container">
        <a href="/docs/sla1c.docx" download class="panel__link">
          <BaseIcon icon="download" width="20px" />
          <span>SLA 1С.docx</span>
        </a>
        <a href="/docs/sla-bm.docx" download class="panel__link">
          <BaseIcon icon="download" width="20px" />
          <span>SLA-BM.docx</span>
        </a>
        <a href="/docs/sla-cloud.docx" download class="panel__link">
          <BaseIcon icon="download" width="20px" />
          <span>SLA-облачная инфраструктура.docx</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon'

export default {
  name: 'PageSLA',
  components: { BaseIcon }
}
</script>

<style lang="scss" scoped>
.sla {
  .banner {
    padding: 56px 0;
    background: #008ACB;
    color: #FFFFFF;
    &__text1 {
      font-weight: 600;
      font-size: 52px;
    }
    &__text2 {
      font-weight: 500;
      font-size: 24px;
    }
  }

  .info {
    margin-top: 60px;

    p {
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 1.75;
    }
  }

  .panel {
    margin-top: 30px;
    .container {
      display: flex;
      flex-wrap: wrap;
    }

    &__link {
      display: flex;
      align-items: center;
      color: $secondary-color;
      margin-right: 30px;
      margin-top: 30px;

      span {
        display: block;
        margin-left: 10px;
      }
    }
  }
}
</style>
